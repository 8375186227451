import {
  useRef,
  useState,
  useEffect,
  ReactNode,
  ComponentProps,
  HTMLProps,
} from 'react'

import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'

import StyledHeader, {
  StyledAppBar,
  // StyledHeader,
  StyledMenuButton,
  StyledTabBar,
} from './styles'

type TabProps = {
  isActive: boolean
  onClick(): void
  className?: string
  children?: ReactNode
}

export function Tab({
  children,
  isActive,
  onClick,
  className,
  ...props
}: TabProps) {
  const tabRef = useRef<HTMLButtonElement>(null)

  const clickHandler = () => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
      })
    }

    if (onClick) {
      onClick()
    }
  }

  return (
    <button
      type='button'
      className={classNames(isActive && 'active', className)}
      {...props}
      onClick={clickHandler}
      ref={tabRef}
    >
      {children}
    </button>
  )
}

type TabBarProps = ComponentProps<typeof StyledTabBar>

export function TabBar({ children, ...props }: TabBarProps) {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const tabBar = useRef<HTMLDivElement>(null)
  const [leftScrollIndicator, setLeftIndicator] = useState(false)
  const [rightScrollIndicator, setRightIndicator] = useState(false)

  useEffect(() => {
    if (scrollContainer.current) {
      setRightIndicator(
        scrollContainer.current.scrollWidth >
          scrollContainer.current.offsetWidth,
      )
    }
  }, [scrollContainer])

  useEffect(() => {
    const container = scrollContainer.current

    function handler() {
      if (container) {
        const { scrollLeft, offsetWidth, scrollWidth, clientWidth } = container
        const scrollLeftMax = scrollWidth - clientWidth

        // Left indicator
        const left = scrollLeft > 56
        if (left !== leftScrollIndicator) {
          setLeftIndicator(left)
        }

        // Right indicator
        const right = scrollLeft < scrollLeftMax && scrollWidth >= offsetWidth

        if (right !== rightScrollIndicator) {
          setRightIndicator(right)
        }
      }
    }

    container?.addEventListener('scroll', handler)

    return () => container?.removeEventListener('scroll', handler)
  }, [leftScrollIndicator, rightScrollIndicator])

  return (
    <StyledTabBar ref={tabBar} {...props}>
      <button
        className={
          'scroll-indicator scroll-indicator--left' +
          (leftScrollIndicator ? ' visible' : '')
        }
        onClick={() => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft = 0
          }
        }}
      >
        <FontAwesomeIcon icon={['far', 'angle-left']} />
      </button>
      <button
        className={
          'scroll-indicator scroll-indicator--right' +
          (rightScrollIndicator ? ' visible' : '')
        }
        onClick={() => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft =
              scrollContainer.current.scrollWidth -
              scrollContainer.current.offsetWidth
          }
        }}
      >
        <FontAwesomeIcon icon={['far', 'angle-right']} />
      </button>
      <div className='scroll-container' ref={scrollContainer}>
        {children}
      </div>
    </StyledTabBar>
  )
}

TabBar.Tab = Tab

export type MenuButtonProps = {
  icon: IconName
  iconType?: IconPrefix
  label: string
} & HTMLProps<HTMLButtonElement> &
  ComponentProps<typeof StyledMenuButton>

export function MenuButton({
  icon,
  label,
  iconType = 'far',
  ...props
}: MenuButtonProps) {
  return (
    <StyledMenuButton type='button' aria-label={label} {...props}>
      <FontAwesomeIcon icon={[iconType, icon]} />
    </StyledMenuButton>
  )
}

type AppBarProps = {
  title: string
  subtitle?: string
  onToggle?(): void

  children?: ReactNode
}

export function AppBar({
  title,
  subtitle,
  onToggle,
  children,
  ...props
}: AppBarProps) {
  const { t } = useTranslation()

  return (
    <StyledAppBar {...props}>
      <MenuButton
        icon='bars'
        label={t('header:buttons.open menu')}
        title={t('header:buttons.open menu')}
        onClick={onToggle}
        disabled={!onToggle}
      />
      <h1>
        {title}
        {subtitle ? <span className='subtitle'>{subtitle}</span> : null}
      </h1>
      {children}
    </StyledAppBar>
  )
}

AppBar.MenuButton = MenuButton

type HeaderProps = {
  children?: ReactNode
}

export function Header({ children }: HeaderProps) {
  return <StyledHeader>{children}</StyledHeader>
}

Header.AppBar = AppBar
Header.TabBar = TabBar

export default Header
